/**
 * Organisation management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  organisationActionCreators,
  IOrganisationState,
  IOrganisationProps,
} from '../../controllers/OrganisationController';

/**
 * Utilities
 */
import { isProtected } from '../../common/Utils';

/**
 * Components
 */
import GoBackComponent from '@govteams/library/components/ManageOrganisation/commons/GoBackComponent/';
import ListComponent from '@govteams/library/components/ManageOrganisation/ManageMembers/ListComponent';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
import {
  selectionActions,
  constants as componentConstants,
  tooltips
} from './OrganisationMembers.constants';

type IMergedProps = IOrganisationState &
  IOrganisationProps &
  RouteComponentProps;

const OrganisationMembersComponent = (props: IMergedProps) => {

  /**
   * Props
   */
  const {
    domains,
    isExporting,
    organisation,
    error,
    udfMasterList,
    membersResult,
    membersActionResult,
    manageMembersFilters,
    dispatch,
    match,

    organisationActionCreators,
  } = props;

  const onGoBack = () => dispatch(replace(`${constants.SCREEN_ORGANISATION}/${match.params['organisationId']}`));

  /**
   * render
   */
  return (
    <div className='orgMembersManagement-page'>
      <GoBackComponent
        linkLabel={constants.LINK_BACKTOORGMANAGEMENT}
        handleClick={onGoBack}
      />

      <ListComponent
        domains={domains}
        isExporting={isExporting}
        organisation={organisation}
        error={error}
        udfMasterList={udfMasterList}
        membersResult={membersResult}
        membersActionResult={membersActionResult}
        manageMembersFilters={manageMembersFilters}
        match={match}
        isProtected={isProtected}

        selectionActions={selectionActions}
        constants={componentConstants}
        tooltips={tooltips}

        organisationActionCreators={organisationActionCreators}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isExporting: state.organisation.isExporting,
  organisation: state.organisation.organisation,
  membersResult: state.organisation.membersResult,
  membersActionResult: state.organisation.membersActionResult,
  manageMembersFilters: state.organisation.manageMembersFilters,
  udfMasterList: state.organisation.udfMasterList,
  domains: state.organisation.domains,
  licence: state.organisation.licence,
  membersCsv: state.organisation.membersCsv,
  error: state.organisation.error
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(organisationActionCreators, dispatch),
  dispatch
});

export const OrganisationMembers = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationMembersComponent);

export default OrganisationMembers;

export const actions = {
  ORG_FETCH_REQUEST: 'ORG_FETCH_REQUEST',
  ORG_FETCH_RESPONSE: 'ORG_FETCH_RESPONSE',
  ORG_SAVE_REQUEST: 'ORG_SAVE_REQUEST',
  ORG_SAVE_RESPONSE: 'ORG_SAVE_RESPONSE',
  ORG_FETCH_MESSAGE_REQUEST: 'ORG_FETCH_MESSAGE_REQUEST',
  ORG_FETCH_MESSAGE_RESPONSE: 'ORG_FETCH_MESSAGE_RESPONSE',
  ORG_EDIT_FORM_RESET: 'ORG_EDIT_FORM_RESET',
  ORG_FETCH_ALL_REQUEST: 'ORG_FETCH_ALL_REQUEST',
  ORG_FETCH_ALL_RESPONSE: 'ORG_FETCH_ALL_RESPONSE',
  ORG_FETCH_ADMINS_REQUEST: 'ORG_FETCH_ADMINS_REQUEST',
  ORG_FETCH_ADMINS_RESPONSE: 'ORG_FETCH_ADMINS_RESPONSE',
  ORG_FETCH_MEMBERS_REQUEST: 'ORG_FETCH_MEMBERS_REQUEST',
  ORG_FETCH_MEMBERS_RESPONSE: 'ORG_FETCH_MEMBERS_RESPONSE',
  ORG_DOMAINS_FETCH_REQUEST: 'ORG_DOMAINS_FETCH_REQUEST',
  ORG_DOMAINS_FETCH_RESPONSE: 'ORG_DOMAINS_FETCH_RESPONSE',
  ORG_LICENCE_FETCH_REQUEST: 'ORG_LICENCE_FETCH_REQUEST',
  ORG_LICENCE_FETCH_RESPONSE: 'ORG_LICENCE_FETCH_RESPONSE',
  ORG_MEMBERS_SEARCH_REQUEST: 'ORG_MEMBERS_SEARCH_REQUEST',
  ORG_MEMBERS_SEARCH_RESPONSE: 'ORG_MEMBERS_SEARCH_RESPONSE',
  ORG_MEMBERS_EXPORTCSV_REQUEST: 'ORG_MEMBERS_EXPORTCSV_REQUEST',
  ORG_MEMBERS_EXPORTCSV_RESPONSE: 'ORG_MEMBERS_EXPORTCSV_RESPONSE',
  ORG_MEMBERS_CHANGE_STATUS_REQUEST: 'ORG_MEMBERS_CHANGE_STATUS_REQUEST',
  ORG_MEMBERS_CHANGE_STATUS_RESPONSE: 'ORG_MEMBERS_CHANGE_STATUS_RESPONSE',
  ORG_MEMBERS_LICENCE_ALLOCATE_REQUEST: 'ORG_MEMBERS_LICENCE_ALLOCATE_REQUEST',
  ORG_MEMBERS_LICENCE_ALLOCATE_RESPONSE: 'ORG_MEMBERS_LICENCE_ALLOCATE_RESPONSE',
  ORG_MEMBERS_LICENCE_DEACTIVATE_REQUEST: 'ORG_MEMBERS_LICENCE_DEACTIVATE_REQUEST',
  ORG_MEMBERS_LICENCE_DEACTIVATE_RESPONSE: 'ORG_MEMBERS_LICENCE_DEACTIVATE_RESPONSE',
  ORG_MEMBERS_LICENCE_REMOVE_REQUEST: 'ORG_MEMBERS_LICENCE_REMOVE_REQUEST',
  ORG_MEMBERS_LICENCE_REMOVE_RESPONSE: 'ORG_MEMBERS_LICENCE_REMOVE_RESPONSE',
  ORG_MEMBERS_LICENCE_RESERVE_REQUEST: 'ORG_MEMBERS_LICENCE_RESERVE_REQUEST',
  ORG_MEMBERS_LICENCE_RESERVE_RESPONSE: 'ORG_MEMBERS_LICENCE_RESERVE_RESPONSE',
  ORG_MEMBERS_LICENCE_BLOCK_REQUEST: 'ORG_MEMBERS_LICENCE_BLOCK_REQUEST',
  ORG_MEMBERS_LICENCE_BLOCK_RESPONSE: 'ORG_MEMBERS_LICENCE_BLOCK_RESPONSE',
  ORG_MEMBERS_LICENCE_FORM_RESET: 'ORG_MEMBERS_FORM_RESET',
  ORG_MEMBERS_FILTER_SELECTION_SET: 'ORG_MEMBERS_FILTER_SELECTION_SET',
  ORG_MEMBERS_FILTER_SELECTION_RESET: 'ORG_MEMBERS_FILTER_SELECTION_RESET',
  ORG_FETCH_MEMBERS_RESERVED_REQUEST: 'ORG_FETCH_MEMBERS_RESERVED_REQUEST',
  ORG_FETCH_MEMBERS_RESERVED_RESPONSE: 'ORG_FETCH_MEMBERS_RESERVED_RESPONSE',
  ORG_FETCH_MEMBERS_BLOCKED_REQUEST: 'ORG_FETCH_MEMBERS_BLOCKED_REQUEST',
  ORG_FETCH_MEMBERS_BLOCKED_RESPONSE: 'ORG_FETCH_MEMBERS_BLOCKED_RESPONSE',
  ORG_FETCH_EXTERNAL_MEMBERS_REQUEST: 'ORG_FETCH_EXTERNAL_MEMBERS_REQUEST',
  ORG_FETCH_EXTERNAL_MEMBERS_RESPONSE: 'ORG_FETCH_EXTERNAL_MEMBERS_RESPONSE',
  ORG_EXTERNAL_MEMBER_REVIEW_REQUEST: 'ORG_EXTERNAL_MEMBER_REVIEW_REQUEST',
  ORG_EXTERNAL_MEMBER_REVIEW_RESPONSE: 'ORG_EXTERNAL_MEMBER_REVIEW_RESPONSE',
  ORG_EXTERNAL_MEMBER_REMOVE_REQUEST: 'ORG_EXTERNAL_MEMBER_REMOVE_REQUEST',
  ORG_EXTERNAL_MEMBER_REMOVE_RESPONSE: 'ORG_EXTERNAL_MEMBER_REMOVE_RESPONSE',
  ORG_FETCH_IP_RANGE_REQUEST: 'ORG_FETCH_IP_RANGE_REQUEST',
  ORG_FETCH_IP_RANGE_RESPONSE: 'ORG_FETCH_IP_RANGE_RESPONSE',
  ORG_FETCH_ORG_DEFAULT_COMMUNITY_REQUEST: 'ORG_FETCH_ORG_DEFAULT_COMMUNITY_REQUEST',
  ORG_FETCH_ORG_DEFAULT_COMMUNITY_RESPONSE: 'ORG_FETCH_ORG_DEFAULT_COMMUNITY_RESPONSE',
  ORG_UDF_MASTER_FETCH_REQUEST: 'ORG_UDF_MASTER_FETCH_REQUEST',
  ORG_UDF_MASTER_FETCH_RESPONSE: 'ORG_UDF_MASTER_FETCH_RESPONSE',
  ORG_UDF_MASTER_SAVE_REQUEST: 'ORG_UDF_MASTER_SAVE_REQUEST',
  ORG_UDF_MASTER_SAVE_RESPONSE: 'ORG_UDF_MASTER_SAVE_RESPONSE',
  ORG_UDF_MASTER_DELETE_REQUEST: 'ORG_UDF_MASTER_DELETE_REQUEST',
  ORG_UDF_MASTER_DELETE_RESPONSE: 'ORG_UDF_MASTER_DELETE_RESPONSE',
  ORG_UDF_VALUE_BULK_SAVE_REQUEST: 'ORG_UDF_VALUE_BULK_SAVE_REQUEST',
  ORG_UDF_VALUE_BULK_SAVE_RESPONSE: 'ORG_UDF_VALUE_BULK_SAVE_RESPONSE',
  ORG_UDF_VALUE_FETCH_REQUEST: 'ORG_UDF_VALUE_FETCH_REQUEST',
  ORG_UDF_VALUE_FETCH_RESPONSE: 'ORG_UDF_VALUE_FETCH_RESPONSE',
  ORG_UDF_VALUE_SAVE_REQUEST: 'ORG_UDF_VALUE_SAVE_REQUEST',
  ORG_UDF_VALUE_SAVE_RESPONSE: 'ORG_UDF_VALUE_SAVE_RESPONSE',
  ORG_UDF_VALUE_DELETE_REQUEST: 'ORG_UDF_VALUE_DELETE_REQUEST',
  ORG_UDF_VALUE_DELETE_RESPONSE: 'ORG_UDF_VALUE_DELETE_RESPONSE',
  ORG_CLEAR_ACTION_RESULT: 'ORG_CLEAR_ACTION_RESULT',
  ORG_REMOVE_USER_SAVE_REQUEST: 'ORG_REMOVE_USER_SAVE_REQUEST',
  ORG_REMOVE_USER_SAVE_RESPONSE: 'ORG_REMOVE_USER_SAVE_RESPONSE',
};

export default actions;
